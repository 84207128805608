/* General Styles for the Background and Overlay */
.home-page {
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -1;
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

/* Header Styles for Logo and Text */
.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-text {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: 'Roboto', sans-serif;
}

body {
    font-family: 'Roboto', sans-serif;
}

.login-error {
    color: red;
    margin-bottom: 15px;
    text-align: center;
}

.logo-container {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    animation: floatIn 1s ease-out;
}

.logo {
    height: 80px;
    margin-right: 20px;
}

/* Typewriter Effect Styles */
.typewriter-container {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: white;
    position: absolute;
    width: 100%;
    top: 20%;
    transform: translateY(-50%);
    z-index: 3;
    text-transform: uppercase;
}

.typewriter-container .Typewriter__wrapper {
    color: white !important;
    font-weight: bold !important;
}

.form-container {
    position: relative;
    width: 350px;
    padding: 40px;
    margin: 60px auto 0;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    animation: slideUp 0.5s ease-out forwards;
}

.form-heading {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-group {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.form-input {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    background: rgba(255, 255, 255, 0.7);
    transition: border-color 0.3s, background-color 0.3s;
    width: 100%;
}

.form-input:focus {
    border-color: #007bff;
}

.form-button {
    padding: 15px 0;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
    width: 100%;
}

.form-button:hover {
    background-color: #0056b3;
}

/* Updated Forgot Password Styles */
.forgot-password-link {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    margin-top: 10px;
    text-decoration: underline;
    font-size: 14px;
    transition: color 0.3s;
}

.forgot-password-link:hover {
    color: #0056b3;
}

.forgot-password-container {
    text-align: center;
    margin-top: 20px;
}

.forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    max-width: 350px;
    animation: fadeIn 0.5s ease-out forwards;
}

.forgot-password-form input[type="email"] {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 16px;
    width: 100%;
    outline: none;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
}

.forgot-password-form input[type="email"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.forgot-password-form .form-button {
    padding: 15px 0;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 100%;
}

.forgot-password-form .form-button:hover {
    background-color: #0056b3;
}

.reset-message {
    color: green;
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

/* Footer Styles */
.footer {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    color: white;
    z-index: 2;
}

.footer-text {
    font-size: 14px;
    opacity: 0.7;
}

/* Animations */
@keyframes floatIn {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Media Queries for Mobiles */
@media only screen and (max-width: 480px) {
    .header-text {
        font-size: 14px;
    }

    .form-container {
        width: 175px;
        padding: 20px;
        margin: 30px auto 0;
    }

    .form-heading {
        font-size: 12px;
    }

    .form-input,
    .form-button {
        padding: 10px;
        font-size: 14px;
    }

    .footer-text {
        font-size: 7px;
    }
}
