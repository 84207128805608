.reset-password-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

.reset-password-form {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 400px;
}

.reset-password-form h2 {
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 20px;
}

.form-input {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    background: rgba(255, 255, 255, 0.9);
    transition: border-color 0.3s;
}

.form-input:focus {
    border-color: #007bff;
}

.form-button {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
}

.form-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-bottom: 15px;
}

.success-message {
    color: green;
    margin-bottom: 15px;
}
