/* Sidebar Styles */
.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #252636;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  z-index: 1000;
  overflow-y: auto;
  transition: transform 0.3s ease;
}

/* Main Content Styles */
.main-content {
  margin-left: 250px;
  padding: 50px;
  background-color: #252636;
  color: #a1a0a0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Fill the entire height of the viewport */
}

.main-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.button-contained {
  background-color: #484BB1;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 12px 24px;
}

.button-contained:hover {
  background-color: #3b3e97;
}

/* Adjusted Typography Styles */
.main-content h4 {
  font-size: 2.2rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}
